import { NgClass } from '@angular/common'
import { ChangeDetectionStrategy, Component, ViewEncapsulation, input } from '@angular/core'

@Component({
  selector: 'sb-badge',
  standalone: true,
  imports: [NgClass],
  template: `
    <span
      class="new-badge"
      [ngClass]="{
        'badge-success': variant() === 'success',
        'badge-warning': variant() === 'warning',
        'badge-error': variant() === 'error',
        'badge-primary': variant() === 'primary',
        'badge-secondary': variant() === 'secondary'
      }"
    >
      <ng-content />
    </span>
  `,
  styles: `
    sb-badge > .new-badge {
      padding: 4px 6px;
      white-space: nowrap;

      mat-icon.mat-icon {
        margin: 0px;
      }
    }
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  variant = input<'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'error'>('default')
}
